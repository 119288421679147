import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepThree = () => {
  return (
    <>
      <StyledTopBar2>
      <div class="back-arrow">
        <img src={require("../images/left-arrow.svg").default} alt="" />
      </div>

      <div class="next-arrow">
        <img src={require("../images/right-arrow.svg").default} alt="" />
      </div>
  <div className="title">Register</div>


      </StyledTopBar2>

      <div class="hero-95 blue-background">


        <img src={require("../images/pay-card.svg").default} alt="" className="intro-img2" />

        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >

        </p>
        <p className="" style={{color: "#555", textAlign: "center", float: "left", width: "100%"}}>

        Please fill your details below
        </p>


        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">


        <input
          type="text"
          className="fill-box"
          placeholder="Full Name"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Email"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Phone Number"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Password"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Repeat Password"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Card Number"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Expiry Date"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="CVV"
        />

        </div>
        </div>
        <div className="full-width">
        <a href="/login">
          <div className="btn btn-green full-width" style={{border: "none"}}>Register</div>
          </a>
        </div>
        <div className="bottom-spacer">

        </div>


      </div>

    </>
  );
};

export default StepThree;
