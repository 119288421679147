import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {  useHistory } from "react-router-dom";
import StepWizard from "react-step-wizard";

import Needs from "./needs";
import NeedsAnalysis from "./needs-analysis";
import Car from "./car";
import Household from "./house";
import Funeral from "./funeral";
import Offer from "./offer";
import Thanks from "./thanks";

const Wizard = () => {
  let history = useHistory();

  const [state, updateState] = useState({
    form: {},
    demo: false,
  });

  const [token, setToken] = useState("");
  const [user, setUser] = useState([]);

  const { SW } = state;

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user"));

    if (userToken && !!userData) {
      setToken(userToken);
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    if (token && !!user) {
      getCoverDetails()
    }
  }, [token, user])

  const setInstance = (SW) =>
    updateState({
      ...state,
      SW,
    });

  const getCoverDetails = async () => {
    if (token && !!user) {
      const response = await fetch(
        `https://coverx-api.herokuapp.com/api/v1/policy/${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + token,
          }
        }
      );

      const result = await response.json();

      if (result && result.data.length) {
        history.push("/welcome");
      }
    }
  };

  const submitFormResults = async (data) => {
    if (token && !!user) {
      const response = await fetch(
        `https://coverx-api.herokuapp.com/api/v1/policy/${user.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (result) {
        SW.nextStep();
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          needs: {
            gender: "",
            marital_status: "",
            age: 33,
            number_children: "",
            address_line_1: "",
            address_line_2: "",
            priority_1: "",
            priority_2: "",
            priority_3: "",
          },
          car_insurance: {
            make: "",
            model: "",
            year: "",
            primary_driver: "",
            license_code: "",
            claims_in_last_3_years: false,
          },
          household_cover: {
            address_line_1: "",
            address_line_2: "",
            number_bedrooms: "",
            number_residents: "",
            number_tv: "",
            number_fridges: "",
            number_cellphone: "",
            residence_type: "",
            claims_in_last_5_years: false,
          },
          funeral_cover: {
            number_people_covered: "",
            person_1_name: "",
            person_2_name: "",
            person_3_name: "",
            person_4_name: "",
          },
        }}
        onSubmit={(values) => {
          if (Object.keys(values).length) {
            submitFormResults(values);
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <StepWizard instance={setInstance}>
              <Needs wizardActions={SW} {...props} />
              <NeedsAnalysis wizardActions={SW} {...props} />
              <Car wizardActions={SW} {...props} />
              <Household wizardActions={SW} {...props} />
              <Funeral wizardActions={SW} {...props} />
              <Offer />
              <Thanks />
            </StepWizard>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Wizard;
