import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const FuneralClaim = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">Sent</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/danke.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        36 scafell rd floracliffe
        </p>
        <div class="full-width">

        <p className="hero-text" style={{color: "#555", margin: "-10px 0 50px 0"}}>Thank you, the report was sent successfully to the reciever you requested<br></br></p>

        </div>




        <div className="full-width">
          <Link to="/welcome" className="btn btn-green full-width" style={{border: "none"}}>Back to reports</Link>
        </div>

        <div className="bottom-spacer">
        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default FuneralClaim;
