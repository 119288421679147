import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const Needs = ({ wizardActions, handleChange, handleBlur, values }) => {
  const goToNextStep = (e) => {
    e.preventDefault();

    if (wizardActions) wizardActions.nextStep();
  };

  return (
    <>
      <StyledTopBar>
      <Link to="/step2">
        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>
      </Link>
      <Link to="/start">
        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>
      </Link>
        <div className="title">Needs Analysis</div>
      </StyledTopBar>

      <StyledStart90 className="white-back"   style={{ display: "none", fontWeight:700, color: "#872626"}}>
        <div className="full-width blue-back"></div>
        <div className="full-width"></div>
        <div className="full-width blue-back">
        <div className="progress top10-pm blue-back">
          <img src={require("../images/form.svg").default} alt="" />
        </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">
            Lets get started
            <br />
            Tell us a bit about yourself.
          </p>
        </div>
<div className="full-width top15"></div>
<div className="full-width top15"></div>
        <div className="full-width top15">
          <select className="selector" name="needs.gender" onChange={handleChange}>
            <option value="">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="neutral">Neutral</option>
          </select>
        </div>
        <div className="full-width top15">
          <select className="selector" name="needs.marital_status" onChange={handleChange}>
            <option value="">Marital Status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
          </select>
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="needs.age"
            className="textbox"
            placeholder="Age"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.needs?.age}
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="needs.number_children"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.needs?.number_children}
            className="textbox"
            placeholder="Number of Children/Dependents"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="needs.address_line_1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.needs?.address_line_1}
            className="textbox"
            placeholder="Address"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="needs.address_line_2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.needs?.address_line_2}
            className="textbox"
            placeholder="Postal Code"
          />
        </div>


        <div className="full-width" style={{ "margin-top": "20px" }}>
          <Link to="/#" onClick={goToNextStep} className="btn btn-blue">
            next
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>
      </StyledStart90>
    </>
  );
};

export default Needs;
