import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const HouseClaim = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">household insurance claim</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/house-home.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >
          36 scafell Road florida
        </p>


        <div class="width-15pad top15 go-left">
        <div class="claim-title">
        <p style={{margin:0, color: "#666", }}>
          Please take pictures of the damage.
        </p>
        </div>
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/placeholder.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/placeholder.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/placeholder.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/placeholder.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/placeholder.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="icon-house go-right">
              <div class="icon">
                <img src={require("./images/cam.svg").default} alt="" class="unread" />
              </div>
            </div>
          </div>
          <div class="claim-title">
          <p style={{margin:0, color: "#666", }}>
            Please fill in the incident details.
          </p>
          </div>
          <div class="fill-icon alert-neutral">
          <input
            type="text"
            className="fill-box"
            placeholder="Date of Incident"
          />
          <input
            type="text"
            className="fill-box"
            placeholder="Type of Incident"
          />
          <input
            type="text"
            className="fill-box"
            placeholder="Description"
          />
          <input
            type="text"
            className="fill-box"
            placeholder="Police Report Number"
          />

          </div>
        </div>

        <div className="full-width">
          <Link to="/#" className="btn btn-green full-width" style={{border: "none"}}>Submit Claim</Link>
        </div>

        <div className="bottom-spacer">
        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default HouseClaim;
