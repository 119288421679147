import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CarPage = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">Car insurance</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/car-home.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >
          2012 Mini Cooper S
        </p>

        <div class="full-width">

          <div class="left-text">Monthly Premium</div>
          <div class="right-text">R972.00</div>
          <div class="left-text">Excess</div>
          <div class="right-text">R4,500.00</div>
          <div class="left-text">Third party cover</div>
          <div class="right-text">R1,500,000.00</div>
          <div class="left-text">Courtesy Car</div>
          <div class="right-text">No</div>
          <div class="left-text">Status</div>
          <div class="right-text">Up to date</div>

        </div>
        <div class="width-15pad top15 go-left">
        <a href="/carclaim">
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/claim.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="home-item">
              <div class="insured-title" style={{marginTop: "10px"}}>
                <span class="bolda">Submit a Claim</span>
                <br />I have had an accident
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/caradjust">
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/adjust.svg").default} alt="" class="unread" />

              </div>
            </div>
            <div class="home-item">
              <div class="insured-title">
                <span class="bolda">Adjust My Cover</span>
                <br />I want to add, remove or adjust items on my cover
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />

              </div>
            </div>
          </div>
          </a>
          <a href="/payment">
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/pay.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="home-item">
              <div class="insured-title">
                <span class="bolda">Make Payment</span>
                <br />I want to pay for my premium or any outstanding payments
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />
                <img src="images/do-go.svg" alt="" class="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/documents">
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/document.svg").default} alt="" class="unread" />

              </div>
            </div>
            <div class="home-item">
              <div class="insured-title" style={{marginTop: "10px"}}>
                <span class="bolda">View Policy Documents</span>
                <br />I need a copy of my documents
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/do-go.svg").default} alt="" class="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/support">
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/contact.svg").default} alt="" class="unread" />

              </div>
            </div>
            <div class="home-item">
              <div class="insured-title" style={{marginTop: "10px"}}>
                <span class="bolda">Contact my insurer</span>
                <br />I have an enquiry to make
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/do-go.svg").default} alt="" class="unread" />
              </div>
            </div>
          </div>
          </a>
        </div>

        <div className="bottom-spacer">

        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default CarPage;
