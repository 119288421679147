import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Support = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">History</div>
        </div>

      </div>
      <div className="hero-90 blue-background">



        <p className="hero-text" style={{color: "#555"}}>
          <span className="boldie">Your Report Archive</span>
          <br />
          please note that archived report can not be viewed again, if you wish to view them you will have to open them as a fresh report
        </p>
        <div className="width-15pad go-left">

          <a href="/addservice">
          <div className="home-icon alert-neutral">

            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/house-home.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="home-item2">
            <div className="insured-title">
            <span className="bolda">36 Scafell Rd Florida</span><br></br>Thomas Mkhize<br></br><span style={{color: "#c52f2e"}}>26.08.2021</span>
            </div>

            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/refresh.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/bin.svg").default} alt="" className="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/housepage">
          <div className="home-icon alert-neutral">

            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/house-home.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="home-item2">
            <div className="insured-title">
            <span className="bolda">22 westbrook sandton</span><br></br>John Hook<br></br><span style={{color: "#c52f2e"}}>22.02.2021</span>
            </div>

            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/refresh.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/bin.svg").default} alt="" className="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/housepage">
          <div className="home-icon alert-neutral">

            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/house-home.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="home-item2">
            <div className="insured-title">
            <span className="bolda">14 mdeni Rivonia</span><br></br>James Moyo<br></br><span style={{color: "#c52f2e"}}>26.01.2021</span>
            </div>

            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/refresh.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/bin.svg").default} alt="" className="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/housepage">
          <div className="home-icon alert-neutral">

            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/house-home.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="home-item2">
            <div className="insured-title">
            <span className="bolda">19 Hacoots rd Randburg</span><br></br>Phil Jones<br></br><span style={{color: "#c52f2e"}}>25.05.2021</span>
            </div>

            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/refresh.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/bin.svg").default} alt="" className="unread" />
              </div>
            </div>
          </div>
          </a>
          <a href="/housepage">
          <div className="home-icon alert-neutral">

            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/house-home.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="home-item2">
            <div className="insured-title">
            <span className="bolda">198 Cotton rd Midrand</span><br></br>Denis Paul<br></br><span style={{color: "#c52f2e"}}>26.08.2021</span>
            </div>

            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/refresh.svg").default} alt="" className="unread" />
              </div>
            </div>
            <div className="icon-house">
              <div className="icon">
                <img src={require("./images/bin.svg").default} alt="" className="unread" />
              </div>
            </div>
          </div>
          </a>




        </div>
        <div  className="width-15pad top15">


        <div className="bottom-spacer">
        </div>
        </div>


      </div>

              <div className="footer">
                <Link to="/welcome">
                <div className="footer-icon">
                  <img src={require("./images/home.svg").default} alt="" className="footer-link" />
                </div>
                </Link>
                <div className="footer-icon">
                  <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
                </div>
                <a href="/support">
                <div className="footer-icon">
                  <img src={require("./images/support.svg").default} alt="" className="footer-link" />
                </div>
                </a>
              </div>
    </div>
  );
};

export default Support;
