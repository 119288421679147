import React from "react";
import { Link } from "react-router-dom";
import { StyledSplashScreen } from "../styled"

const Splash = () => {
  return (
    <Link to="/step1">
      <StyledSplashScreen>
        <div className="full-width">
          <div className="">
            <img src={require("../images/splash.svg").default} alt="" className="responsive" />
          </div>
        </div>

      </StyledSplashScreen>
    </Link>
  );
};

export default Splash;
