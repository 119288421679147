import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const FuneralAdjust = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">adjust funeral cover</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

      <div class="progress">
      <img src={require("./images/fun-home.svg").default} alt="" />
      </div>
      <p
        class="hero-text"
        style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
      >
        5 members R250,000.00
      </p>

      <div class="full-width">

        <div class="left-text">Monthly Premium</div>
        <div class="right-text">R352.00</div>
        <div class="left-text">Payout</div>
        <div class="right-text">R50,000.00</div>
        <div class="left-text">Members</div>
        <div class="right-text">5</div>
        <div class="left-text">Status</div>
        <div class="right-text">Active</div>

        </div>
        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Change my payout amount
        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          Value="50,000.00"
        />
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Change number of members
        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          Value="5"
        />
        </div>

        </div>
        <div className="full-width">
          <Link className="btn btn-green full-width" style={{border: "none"}}>Submit Changes</Link>
        </div>
        <div className="bottom-spacer">

        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default FuneralAdjust;
