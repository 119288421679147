import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const AddService = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">get report</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <img src={require("./images/report.svg").default} alt="" class="home-img" />
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        Generate a report
        </p>



        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">

        <select className="fill-box" style={{width:"96%",}}>
        <option value="">Select Province</option>
          <option value="">ESTERN CAPE</option>
          <option value="">FREE STATE</option>
          <option value="single">GAUTENG</option>
          <option value="married">KWAZULU NATAL</option>
          <option value="">LIMPOPO</option>
          <option value="">MPUMALANGA</option>
          <option value="single">NORTHWEST</option>
          <option value="married">NORTHERN CAPE</option>
          <option value="married">WESTERN CAPE</option>
        </select>
        <select className="fill-box" style={{width:"96%",}}>
        <option value="">Select Suburb</option>
          <option value="">ROODEPOORT</option>
          <option value="">SANDTON</option>
          <option value="">RANDBURG</option>
          <option value="">BEDFORDVIEW</option>
        </select>
        <input
          type="text"
          className="fill-box"
          placeholder="Street address"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="ERF"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Portion"
        />

        </div>
        </div>
        <div className="full-width">
        <a href="/housepage">
          <div className="btn btn-green full-width" style={{border: "none"}}>View report</div>
          </a>
        </div>
        <div className="bottom-spacer">

        </div>

        <div className="footer">
          <Link to="/welcome">
          <div className="footer-icon">
            <img src={require("./images/home.svg").default} alt="" className="footer-link" />
          </div>
          </Link>
          <a href="/settings">
          <div className="footer-icon">
            <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
          </div>
          </a>
          <a href="/support">
          <div className="footer-icon">
            <img src={require("./images/support.svg").default} alt="" className="footer-link" />
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddService;
