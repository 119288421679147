import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const Offer = ({ wizardActions }) => {
  const goToFirstStep = (e) => {
    e.preventDefault();

    if (wizardActions) wizardActions?.firstStep();
  };

  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const car = randomNumber(100, 900)
  const household = randomNumber(100, 1000)
  const funeral = randomNumber(50, 980)

  return (
    <>
      <StyledTopBar>
      <Link to="/step2">
        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>
      </Link>
      <Link to="/start">
        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>
      </Link>
        <div className="title"> Our Offer</div>
      </StyledTopBar>

      <StyledStart90 className="white-back">
      <div class="full-width top15">
    </div>

  <div class="progress">
    <img src={require("../images/quote.svg").default} alt="" />
  </div>
  <div class="full-width">
    <p class="hero-text">
      Below  is the ESTIMATE for the coverage you have requested, what would you like to do next?
    </p>
  </div>
  <div class="full-width">
    <div class="left-text">
    Car insurance prenium
    </div>
    <div class="right-text">
      R1,{car}.00
    </div>
    <div class="left-text">
    Excess
    </div>
    <div class="right-text">
      R4,500.00
    </div>
    <div class="left-text">
      Insured Value
    </div>
    <div class="right-text">
      R220,000.00
    </div>
    <div class="border-line">
    </div>
    <div class="left-text">
      Household premium
    </div>
    <div class="right-text">
      R{household}.00
    </div>
    <div class="left-text">
    Coverage
    </div>
    <div class="right-text">
      Theft & Damage
    </div>
    <div class="left-text">
      Insured Value
    </div>
    <div class="right-text">
      R100,000.00
    </div>
    <div class="border-line">
    </div>
    <div class="left-text">
      Funeral Cover Premium
    </div>
    <div class="right-text">
      R{funeral}.00
    </div>
    <div class="left-text">
      Payout per member
    </div>
    <div class="right-text">
      R70,000.00
    </div>
    <div class="left-text">
    Members
    </div>
    <div class="right-text">
      5
    </div>
    <div class="border-line">
    </div>
    <div class="left-text">
    TOTAL
    </div>
    <div class="right-text">
      R2,085.00
    </div>
  </div>
  <div class="full-width">
  </div>
  <div class="full-width top15">
</div>
  <div class="width-15pad go-left top15">
  <Link to="/#" onClick={goToFirstStep}>
  <div className="home-icon alert-neutral">
    <div className="icon-house">
      <div className="icon">
        <img src={require("../images/calculate.svg").default} alt="" className="unread" />
      </div>
    </div>
    <div className="home-item">
    <div className="insured-title">
    <br></br>Recalculate Premiums
    </div>
    <div className="insured-status color-bad">

    </div>
    </div>
    <div className="icon-house">
      <div className="icon">
        <img src={require("../images/do-go.svg").default} alt="" className="unread" />
      </div>
    </div>
  </div>
  </Link>
    <Link to="/thanks">
    <div className="home-icon alert-neutral">
      <div className="icon-house">
        <div className="icon">
          <img src={require("../images/thumbs.svg").default} alt="" className="unread" />
        </div>
      </div>
      <div className="home-item">
      <div className="insured-title">
      <br></br>Accept Quotation
      </div>
      <div className="insured-status color-bad">

      </div>
      </div>
      <div className="icon-house">
        <div className="icon">
          <img src={require("../images/do-go.svg").default} alt="" className="unread" />
        </div>
      </div>
    </div>
    </Link>

  </div>

  <div className="bottom-spacer">
  </div>

      </StyledStart90>
    </>
  );
};

export default Offer;
