import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Documents = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">Send a report</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/doc.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
          36 scafell rd FLORACLIFFE
        </p>

        <div class="full-width">

        <p className="hero-text" style={{color: "#555", margin: "-20px 0 -10px 0"}}>To send the above report please fill in the reciever email and subject line below, It will be sent as a PDF document</p>

        </div>
        <div class="width-15pad top15 go-left">



        </div>


        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>

        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          placeholder="Email Address"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Email Subject"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Message"
        />

        </div>

        </div>
        <div className="full-width">
          <a href="/funeralclaim">
          <div className="btn btn-green full-width" style={{border: "none"}}>Send Report</div>
          </a>
        </div>
        <div className="bottom-spacer">

        </div>




      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default Documents;
