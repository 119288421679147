import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const NeedsAnalysis = ({ wizardActions, handleChange }) => {
  const goToNextStep = (e) => {
    e.preventDefault();

    if (wizardActions) wizardActions.nextStep();
  };
  return (
    <>
      <StyledTopBar>
      <Link to="/step2">
        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>
      </Link>
      <Link to="/start">
        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>
      </Link>

        <div className="title">Financial Needs Analysis</div>
      </StyledTopBar>

      <StyledStart90 className="white-back">
        <div className="full-width blue-back"></div>
        <div className="full-width blue-back">
        <div className="progress top15 blue-back">
          <img src={require("../images/form.svg").default} alt="" />
        </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">
            What cover matters to you most? select in order of importance
            starting with most important, you must choose at least one.
          </p>
        </div>
        <div className="full-width"></div>
        <div className="full-width top15-pm">
          <select className="selector" name="needs.priority_1" onChange={handleChange}>
            <option value="">Select Priority 1 </option>
            <option value="Car Insurance">Car Insurance</option>
            <option value="Funeral Cover">Funeral Cover</option>
            <option value="Household cover">Household cover</option>
          </select>
        </div>
        <div className="full-width top15">
          <select className="selector" name="needs.priority_2" onChange={handleChange}>
            <option value="">Select Priority 2 </option>
            <option value="Car Insurance">Car Insurance</option>
            <option value="Funeral Cover">Funeral Cover</option>
            <option value="Household cover">Household cover</option>
          </select>
        </div>
        <div className="full-width top15">
          <select className="selector" name="needs.priority_3" onChange={handleChange}>
            <option value="">Select Priority 3 </option>
            <option value="Car Insurance">Car Insurance</option>
            <option value="Funeral Cover">Funeral Cover</option>
            <option value="Household cover">Household cover</option>
          </select>
        </div>

        <div className="full-width" style={{ "margin-top": "20px" }}>
          <Link to="/#" onClick={goToNextStep} className="btn btn-blue thanks-btn">
            next
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>
      </StyledStart90>
    </>
  );
};

export default NeedsAnalysis;
