import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepFour = () => {
  return (
    <>
      <StyledTopBar2>
      <div class="back-arrow">
        <img src={require("../images/left-arrow.svg").default} alt="" />
      </div>

      <div class="next-arrow">
        <img src={require("../images/right-arrow.svg").default} alt="" />
      </div>
  <div className="title">Free Report</div>


      </StyledTopBar2>

      <div class="hero-95 blue-background">

        <img src={require("../images/report.svg").default} alt="" class="home-img" />
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        Get limited report
        </p>



        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">

        <select className="fill-box" style={{width:"96%",}}>
        <option value="">Select Province</option>
          <option value="">ESTERN CAPE</option>
          <option value="">FREE STATE</option>
          <option value="single">GAUTENG</option>
          <option value="married">KWAZULU NATAL</option>
          <option value="">LIMPOPO</option>
          <option value="">MPUMALANGA</option>
          <option value="single">NORTHWEST</option>
          <option value="married">NORTHERN CAPE</option>
          <option value="married">WESTERN CAPE</option>
        </select>
        <select className="fill-box" style={{width:"96%",}}>
        <option value="">Select Suburb</option>
          <option value="">ROODEPOORT</option>
          <option value="">SANDTON</option>
          <option value="">RANDBURG</option>
          <option value="">BEDFORDVIEW</option>
        </select>
        <input
          type="text"
          className="fill-box"
          placeholder="Street address"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="ERF"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Portion"
        />

        </div>
        </div>
        <div className="full-width">
        <a href="/step5">
          <div className="btn btn-green full-width" style={{border: "none"}}>View report</div>
          </a>
        </div>
        <div className="bottom-spacer">

        </div></div>

    </>
  );
};

export default StepFour;
