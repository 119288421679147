import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Support = () => {

  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">Settings</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/user1.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        Your profile
        </p>



        <div class="width-15pad top15 go-left">

        </div>

        <div className="bottom-spacer">

        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default Support;
