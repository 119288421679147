import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { StyledTopBar2 } from '../styled'
import {  useHistory } from "react-router-dom";

const Welcome = () => {
  const [userName, setUserName] = useState('')
  let history = useHistory();

  useEffect(() => {
    const userData = localStorage.getItem('user')
    const {name} = JSON.parse(userData)

    setUserName(name)
  }, [])

  const logOutUser = (e) => {
    e.preventDefault()
    localStorage.clear()
    history.push("/");
  }

  return (
    <>
      <div className="screen">
      <a href="/addservice">
      <img src={require("../images/adding.svg").default} alt="" class="add-new" />
      </a>
        <StyledTopBar2>
          <Link to="/welcome">
            <div className="back-arrow">
              <img src={require("../images/left-arrow.svg").default} alt="" />
            </div>
          </Link>
          <Link to="/welcome">
            <div className="next-arrow">
              <img src={require("../images/right-arrow.svg").default} alt="" />
            </div>
          </Link>
          <div className="progress">
            <div className="title">welcome</div>
          </div>
        </StyledTopBar2>
        <div className="hero-90 blue-background">

          <img src={require("../images/report.svg").default} alt="" class="home-img" />

          <p className="hero-text" style={{color: "#555"}}>
            <span className="boldie">Your reports {userName},</span>
            <br />
            Open, or generate a new report below. Reports older than 7 days will be archived into the history section.
          </p>
          <div className="width-15pad go-left">
            <a href="/housepage">
            <div className="home-icon alert-neutral">

              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/house-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">36 Scafell Rd Florida</span><br></br>Thomas Mkhize
              </div>

              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>
            <a href="/housepage">
            <div className="home-icon alert-neutral">

              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/house-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">26 kingfisher Dr Sandton</span><br></br>Jane Rolland
              </div>

              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>




          </div>
          <div  className="width-15pad top15">

          <div className="full-width top15">
            <Link to="/#" onClick={logOutUser} className="btn btn-invert full-width" style={{color:"#000", border: "none"}}>Logout</Link>
          </div>
          <div className="bottom-spacer">
          </div>
          </div>


        </div>
        <div className="footer">
          <div className="footer-icon">
            <img src={require("../images/home.svg").default} alt="" className="footer-link" />
          </div>
          <a href="/settings">
          <div className="footer-icon">
            <img src={require("../images/settings.svg").default} alt="" className="footer-link" />
          </div>
          </a>
          <a href="/support">
          <div className="footer-icon">
            <img src={require("../images/support.svg").default} alt="" className="footer-link" />
          </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Welcome;
