import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CarAdjust = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">adjust Car insurance</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/car-home.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >
          2012 Mini Cooper S
        </p>

        <div class="full-width">

          <div class="left-text">Monthly Premium</div>
          <div class="right-text">R972.00</div>
          <div class="left-text">Excess</div>
          <div class="right-text">R4,500.00</div>
          <div class="left-text">Third party cover</div>
          <div class="right-text">R1,500,000.00</div>
          <div class="left-text">Courtesy Car</div>
          <div class="right-text">No</div>
          <div class="left-text">Status</div>
          <div class="right-text">Up to date</div>

        </div>
        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Change My Excess
        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          Value="4,500.00"
        />
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Change my third party cover
        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          Value="1,500,000.00"
        />
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Change my courtesy car
        </p>
          </div>
        <select className="fill-box" style={{width:"96%",}}>
          <option value="">No Courtesy car</option>
          <option value="single">Class A (Entry Level)</option>
          <option value="married">Class B (Comfort Lite)</option>
          <option value="divorced">Class C (Comfort Plus)</option>
          <option value="divrced">Class D (Premium)</option>
          <option value="dvorced">Class E (Super Premium)</option>

        </select>
        </div>
        <div class="fill-icon alert-neutral">
        <div class="adjust-title">
        <p style={{margin:0, color: "#666", }}>
          Add and cover an aftermarket extra
        </p>
        </div>
        <input
          type="text"
          className="fill-box"
          placeholder="Item(s) Description"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Item(s) Value e.g R3000"
        />
        </div>
        </div>
        <div className="full-width">
          <Link className="btn btn-green full-width" style={{border: "none"}}>Submit Changes</Link>
        </div>
        <div className="bottom-spacer">

        </div>
      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default CarAdjust;
