import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const HousePage = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">report</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">

        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        detailed property report
        </p>

        <div class="full-width">

          <div class="left-text">Address</div>
          <div class="right-text">36 scafell rd</div>
          <div class="left-text">Suburb & city</div>
          <div class="right-text">Floracliffe, JHB</div>
          <div class="left-text">Owner</div>
          <div class="right-text">Thomas Mkhize</div>
          <div class="left-text">Purchase Amnt</div>
          <div class="right-text">R2,000,000.00</div>
          <div class="left-text">Municipal Valuation</div>
          <div class="right-text">R1,680,000.00</div>
          <div class="left-text">Stand Size</div>
          <div class="right-text">611 SM</div>
          <div class="left-text">ERF</div>
          <div class="right-text">54</div>
          <div class="left-text">Portion</div>
          <div class="right-text">3</div>

        </div>

        <div class="width-15pad top15 go-left">
        <img src={require("./images/map.jpg").default} alt="" class="map-img" />

        </div>
        <div class="full-width">

          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}>TRANSFER HISTORY</div>

          <div class="left-text2">JOHN SAMSON DOE</div>
          <div class="left-text2">R1,580,000.00</div>
          <div class="left-text2">2017/12/12</div>
          <div class="left-text2">DEED: T20690/2020</div>

          <div class="split"></div>

          <div class="left-text2">GODFREY JAMES MALL</div>
          <div class="left-text2">R1,280,000.00</div>
          <div class="left-text2">2008/10/19</div>
          <div class="left-text2">DEED: T20690/2020</div>

          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}><br></br>RECENT SALES NEARBY</div>

          <div class="left-text2">3 KENYA ROAD, FLORIDA HILLS</div>
          <div class="left-text2">John Samson Doe</div>
          <div class="left-text2">R1,580,000.00</div>
          <div class="left-text2">2020/12/12</div>
          <div class="left-text2">0.22 KM </div>

          <div class="split"></div>

          <div class="left-text2">42 SCAFELL ROAD, FLORACLIFFE </div>
          <div class="left-text2">Godfrey Mall</div>
          <div class="left-text2">R1,280,000.00</div>
          <div class="left-text2">2008/10/19</div>
          <div class="left-text2">0.42 KM </div>

          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}><br></br>SUBURB AMENITIES</div>

          <div class="left-text2">MODJADJI GUEST HOUSE</div>
          <div class="left-text2">Bed and Breakfast</div>
          <div class="left-text2">0.6 KM</div>


          <div class="split"></div>

          <div class="left-text2"> CLEARWATER MALL</div>
          <div class="left-text2">Shopping</div>
          <div class="left-text2">2.2 KM</div>

          <div class="split"></div>

          <div class="left-text2"> LIFE FLORA HOSPITAL</div>
          <div class="left-text2">HOSPITAL</div>
          <div class="left-text2">1.2 KM</div>





        </div>


        <div class="full-width">
          <Link to="/documents" class="btn btn-blue top15">
            Send report
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default HousePage;
