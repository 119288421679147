import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Payment = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">payments</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/pay-card.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >
        Make Payments
        </p>
        <p className="" style={{color: "#555", textAlign: "center",}}>
          Good day
          <br />
        Please fill your payment details below
        </p>


        <div class="width-15pad top15 go-left">

        <div class="fill-icon alert-neutral">

        <select className="fill-box" style={{width:"96%",}}>
          <option value="">What are you paying for?</option>
          <option value="">Car Insurance</option>
          <option value="single">Funeral Cover</option>
          <option value="married">Houshold Cover</option>
          <option value="divorced">All Products</option>
        </select>
        <input
          type="text"
          className="fill-box"
          placeholder="Amount"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Card Number"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="CVV"
        />
        <input
          type="text"
          className="fill-box"
          placeholder="Expiry Date"
        />
        </div>
        </div>
        <div className="full-width">
          <Link className="btn btn-green full-width" style={{border: "none"}}>Pay</Link>
        </div>
        <div className="bottom-spacer">

        </div>


      </div>
      <div className="footer">
        <Link to="/welcome">
        <div className="footer-icon">
          <img src={require("./images/home.svg").default} alt="" className="footer-link" />
        </div>
        </Link>
        <a href="/settings">
        <div className="footer-icon">
          <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
        </div>
        </a>
        <a href="/support">
        <div className="footer-icon">
          <img src={require("./images/support.svg").default} alt="" className="footer-link" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default Payment;
