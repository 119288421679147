import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepFive = () => {
  return (
    <>
      <StyledTopBar2>
      <div class="back-arrow">
        <img src={require("../images/left-arrow.svg").default} alt="" />
      </div>

      <div class="next-arrow">
        <img src={require("../images/right-arrow.svg").default} alt="" />
      </div>
  <div className="title">Free Report</div>


      </StyledTopBar2>


      <div class="hero-90 blue-background">

        <div class="progress">

        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#872626"}}
        >
        Report Found - detail hidden
        </p>

        <div class="full-width">

          <div class="left-text">Address</div>
          <div class="right-text">36 scafell rd</div>
          <div class="left-text">Suburb & city</div>
          <div class="right-text">Floracliffe, JHB</div>
          <div class="left-text">Owner</div>
          <div class="right-text">***************</div>
          <div class="left-text">Purchase Amnt</div>
          <div class="right-text">***************</div>
          <div class="left-text">Municipal Valuation</div>
          <div class="right-text">***************</div>
          <div class="left-text">Stand Size</div>
          <div class="right-text">***************</div>
          <div class="left-text">ERF</div>
          <div class="right-text">54</div>
          <div class="left-text">Portion</div>
          <div class="right-text">3</div>

        </div>

        <div class="width-15pad top15 go-left">
        <img src={require("../images/map.jpg").default} alt="" class="map-img" />

        </div>
        <div class="full-width">

          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}>TRANSFER HISTORY</div>

          <div class="left-text2">***************</div>


          <div class="split"></div>

          <div class="left-text2">***************</div>


          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}><br></br>RECENT SALES NEARBY</div>

          <div class="left-text2">***************</div>




          <div class="split"></div>

          <div class="left-text2">*************** </div>



          <div class="left-text" style={{margin:0, fontSize: "16px", textTransform: "uppercase", fontWeight:700, color: "#872626", marginBottom: "5px", textAlign: "left"}}><br></br>SUBURB AMENITIES</div>

          <div class="left-text2">***************</div>



          <div class="split"></div>

          <div class="left-text2">***************</div>


          <div class="split"></div>

          <div class="left-text2">***************</div>






        </div>


        <div class="full-width">
          <Link to="/step2" class="btn btn-blue top15">
            Get full report
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>



      </div>

    </>
  );
};

export default StepFive;
