import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepOne = () => {
  return (
    <>
      <StyledTopBar2>
          <div class="back-arrow">
            <img src={require("../images/left-arrow.svg").default} alt="" />
          </div>

          <div class="next-arrow">
            <img src={require("../images/right-arrow.svg").default} alt="" />
          </div>
            <div className="title">welcome</div>
      </StyledTopBar2>
      <div className="bottom-spacer">
      </div>
      <div class="stage-hero step1-bg">
      <img src={require("../images/intro.svg").default} alt="" class="intro-img" />



      </div>
      <p class="hero-text bottom-zero">
      Your one stop shop for all private, public and commercial real estate Data
      </p>
      <div class="logo-blue">
        <img src={require("../images/logo.svg").default} alt="" class="responsive" />
      </div>
      <div class="full-width">
        <Link to="/step2" class="btn btn-blue top15">
          Get Started
        </Link>
      </div>
    </>
  );
};

export default StepOne;
