import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const Thanks = () => {
  return (
    <>
      <StyledTopBar>
      <Link to="/step2">
        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>
      </Link>
      <Link to="/start">
        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>
      </Link>
        <div className="title"> Payment options</div>
      </StyledTopBar>

      <StyledStart90 className="white-back">
      <div class="full-width top15">
    </div>
    <div class="progress">
      <img src={require("../images/bank.svg").default} alt="" />
    </div>

  <div class="full-width">
    <p class="hero-text">
      Thank You <br /> Your application is submitted, Lets now setup your payment preferences</p>
  </div>
  <div className="full-width top15">
    <select className="selector" name="needs.gender">
      <option value="">Debit Order</option>
      <option value="male">Online transfer</option>
      <option value="female">Payment Center</option>

    </select>
  </div>
  <div className="full-width top15">
    <select className="selector" name="needs.gender">
      <option value="">FNB</option>
      <option value="">Standard Bank</option>
      <option value="">Nedbank</option>
      <option value="">Capitec</option>
      <option value="">Absa</option>
    </select>
  </div>
  <div className="full-width top15">
    <input
      type="text"
      name="name"
      className="textbox"
      placeholder="Branch Code"
    />
  </div>

  <div className="full-width top15">
    <input
      type="text"
      name="name"
      className="textbox"
      placeholder="Account Number"
    />
  </div>
  <div className="full-width top15">
    <input
      type="text"
      name="name"
      className="textbox"
      placeholder="Account Holder Name"
    />
  </div>
  <div className="full-width top15">
    <input
      type="text"
      name="name"
      className="textbox"
      placeholder="ID / Passport Number"
    />
  </div>
  <div class="full-widths">
    <Link to="/welcome" class="btn btn-blue thanks-btn">Submit</Link>
  </div>
  <div className="bottom-spacer">
  </div>
      </StyledStart90>
    </>
  );
};

export default Thanks;
