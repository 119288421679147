import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const NeedsAnalysis = ({ wizardActions, handleChange, handleBlur, values }) => {
  const goToNextStep = (e) => {
    e.preventDefault();

    if (wizardActions) wizardActions.nextStep();
  };
  return (
    <>
      <StyledTopBar>
      <Link to="/step2">
        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>
      </Link>
      <Link to="/start">
        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>
      </Link>
      <div class="title">
        Car insurance
      </div>
      </StyledTopBar>

      <StyledStart90 className="white-back">

        <div className="full-width  blue-back">
        <div className="progress top15">
          <img src={require("../images/car-home.svg").default} alt="" />
        </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">Tell us a bit about your car.</p>
        </div>
        <div className="full-width top15"></div>
        <div className="full-width top15"></div>
        <div className="full-width top15">
          <input
            type="text"
            name="car_insurance.make"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.car_insurance?.make}
            className="textbox"
            placeholder="Vehicle Make"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="car_insurance.model"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.car_insurance?.model}
            className="textbox"
            placeholder="Vehicle Model"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="car_insurance.year"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.car_insurance?.year}
            className="textbox"
            placeholder="Year"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="car_insurance.primary_driver"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.car_insurance?.primary_driver}
            className="textbox"
            placeholder="Primary Driver Name"
          />
        </div>
        <div className="full-width top15">
          <select className="selector" name="car_insurance.license_code" onChange={handleChange}>
            <option value="">Drivers License Code</option>
            <option value="A">Code A</option>
            <option value="B">Code B</option>
          </select>
        </div>
        <div className="full-width top15" name="car_insurance.claims_in_last_3_years" onChange={handleChange}>
          <select className="selector">
            <option value="">Any Claims in past 3 years</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>


        <div className="full-width">
          <Link to="/#" onClick={goToNextStep} className="btn btn-blue thanks-btn">
            next
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>
      </StyledStart90>
    </>
  );
};

export default NeedsAnalysis;
