import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link } from "react-router-dom";

const NeedsAnalysis = ({ wizardActions, handleChange, handleBlur, values }) => {
  const goToNextStep = (e) => {
    e.preventDefault();

    if (wizardActions) wizardActions.nextStep();
  };

  return (
    <>
      <StyledTopBar>
      <div class="back-arrow">
        <img src={require("../images/left-arrow.svg").default} alt="" />
      </div>


      <div class="next-arrow">
        <img src={require("../images/right-arrow.svg").default} alt="" />
      </div>
        <div className="title">household cover</div>
      </StyledTopBar>

      <StyledStart90 className="white-back">

        <div className="full-width  blue-back">
        <div className="progress top15">
          <img src={require("../images/house-home.svg").default} alt="" />
        </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">Tell us a bit about your home.</p>
        </div>
        <div className="full-width top15-pm">
          <input
            type="text"
            name="household_cover.address_line_1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.address_line_1}
            className="textbox"
            placeholder="Address Line 1"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.address_line_2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.address_line_2}
            className="textbox"
            placeholder="Address Line 2"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.number_bedrooms"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.number_bedrooms}
            className="textbox"
            placeholder="Number of bedrooms"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.number_residents"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.number_residents}
            className="textbox"
            placeholder="Number of residents"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.number_tv"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.number_tv}
            className="textbox"
            placeholder="Number of TVs"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.number_fridges"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.number_fridges}
            className="textbox"
            placeholder="Number of Fridges"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="household_cover.number_cellphone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.household_cover?.number_cellphone}
            className="textbox"
            placeholder="Number of Cell Phones"
          />
        </div>

        <div className="full-width top15">
          <select className="selector" name="household_cover.claims_in_last_5_years" onChange={handleChange}>
            <option value="">Any Claims in past 5 years</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>


        <div className="full-width">
          <Link to="/#" onClick={goToNextStep} className="btn btn-blue thanks-btn">
            next
          </Link>
        </div>
        <div className="bottom-spacer">
        </div>
      </StyledStart90>
    </>
  );
};

export default NeedsAnalysis;
