import React from 'react'
import { StyledTopBar2, StyledStartCombined } from './styled'
import { Link } from "react-router-dom"
const Start = () => {
  return (
    <>


      <StyledStartCombined>
        <div className="full-width top60-p">
          <div className="logo">
            <img src={require('./images/logo.svg').default} alt="" className="responsive" />
          </div>
        </div>
        <div className="full-width">
          <Link to="/register" className="btn btn-red top15">get started</Link>
        </div>


        <div className="full-width">
          <Link to="/login" className="btn btn-white top15">login</Link>
        </div>
      </StyledStartCombined>
    </>
  );

}

export default Start
