import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepTwo = () => {
  return (
    <>
      <StyledTopBar2>

          <div class="back-arrow">
            <img src={require("../images/left-arrow.svg").default} alt="" />
          </div>

          <div class="next-arrow">
            <img src={require("../images/right-arrow.svg").default} alt="" />
          </div>
      <div className="title">Getting Started</div>

      </StyledTopBar2>

      <div class="stage-hero step1-bg">
      <img src={require("../images/portfolio.svg").default} alt="" class="hero-img" />


      <p class="hero-text bottom-zero">
      Select your package or login below
      </p>
      <div className="bottom-spacer">
      </div>
      <div className="width-15pad go-left">
      <a href="/step4">
      <div className="home-icon alert-neutral">

        <div className="icon-house">
          <div className="icon">
            <img src={require("../images/pak0.svg").default} alt="" className="unread" />
          </div>
        </div>
        <div className="home-item">
        <div className="insured-title text-center">
        <span className="bolda">Free Report - Limited</span>
        </div>

        </div>
        <div className="icon-house">
        </div>
      </div>
      </a>
        <a href="/step3">
        <div className="home-icon alert-neutral">

          <div className="icon-house">
            <div className="icon">
              <img src={require("../images/pak1.svg").default} alt="" className="unread" />
            </div>
          </div>
          <div className="home-item">
          <div className="insured-title text-center">
          <span className="bolda">Single report - R75.00</span>
          </div>

          </div>
          <div className="icon-house">
          </div>
        </div>
        </a>
        <a href="/step3">
        <div className="home-icon alert-neutral">

          <div className="icon-house">
            <div className="icon">
              <img src={require("../images/pak2.svg").default} alt="" className="unread" />
            </div>
          </div>
          <div className="home-item">
          <div className="insured-title text-center">
          <span className="bolda">150 reports - R499.00 </span>
          </div>

          </div>
          <div className="icon-house">
          </div>
        </div>
        </a>
        <a href="/step3">
        <div className="home-icon alert-neutral">

          <div className="icon-house">
            <div className="icon">
              <img src={require("../images/pak3.svg").default} alt="" className="unread" />
            </div>
          </div>
          <div className="home-item">
          <div className="insured-title text-center">
          <span className="bolda">300 reports - R599.00 </span>
          </div>

          </div>
          <div className="icon-house">
          </div>
        </div>
        </a>
        <a href="/step3">
        <div className="home-icon alert-neutral">

          <div className="icon-house">
            <div className="icon">
              <img src={require("../images/pak4.svg").default} alt="" className="unread" />
            </div>
          </div>
          <div className="home-item">
          <div className="insured-title text-center">
          <span className="bolda">custom - call me back </span>
          </div>

          </div>
          <div className="icon-house">
          </div>
        </div>
        </a>




      </div>

      </div>


      <div class="full-width">
        <Link to="/login" class="btn btn-blue top15">
          Login
        </Link>
      </div>
    </>
  );
};

export default StepTwo;
